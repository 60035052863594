@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

/* montserrat-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url('/assets/fonts/montserrat-v25-latin-regular.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
       url('/assets/fonts/montserrat-v25-latin-regular.ttf') format('truetype'); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}
/* montserrat-500 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src: url('/assets/fonts/montserrat-v25-latin-500.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
       url('/assets/fonts/montserrat-v25-latin-500.ttf') format('truetype'); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}
/* montserrat-700 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: url('/assets/fonts/montserrat-v25-latin-700.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
       url('/assets/fonts/montserrat-v25-latin-700.ttf') format('truetype'); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}

* {
  font-family: "Montserrat", sans-serif;
}

.ip {
  @apply bg-white relative border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 cursor-default focus:outline-none focus:ring-1 focus:ring-primary focus:border-primary sm:text-sm;
}

.button-animation {
  @apply cursor-pointer scale-100 active:scale-[97.5%] transition ease-in-out duration-200 active:brightness-95 active:opacity-100 hover:opacity-75 disabled:scale-100 disabled:hover:opacity-100 disabled:active:scale-100 disabled:cursor-not-allowed
}

.tooltip-container {
  @apply bg-gray-600 rounded-md font-semibold;
  text-align: center;
  z-index: 100;
  position: fixed;
  padding: 6px 12px;
  font-size: 0.66rem;
  line-height: initial;
  color: white;
  width: auto;
  box-sizing: border-box;
  opacity: 0;
  transform: translate(-50%, -30%);
  animation: tooltip-slide 0.18s ease-out 0.5s;
  animation-fill-mode: forwards;
  pointer-events: none;
}

@keyframes tooltip-slide {
0% {
  opacity: 0;
  transform: translate(-50%, -30%);
}
100% {
  opacity: 1;
  transform: translate(-50%, 0);
}
}

router-outlet + * {
  @apply flex flex-col overflow-y-auto h-full;
}